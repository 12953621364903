import React from "react";
import Navigator from "./navigation/Navigator";

const App = () => {
    return (
        <div>
            <Navigator />
        </div>
    )
};
export default App;